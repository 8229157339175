import * as React from 'react'
import Homepage from 'components/Homepage'

const IndexPage = () => {
  return (
    <>
      <Homepage />
    </>
  )
}

export default IndexPage
